import React, { Component ,lazy, Suspense} from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ScrollToTop from './page-animation/ScrollToTop';
import Spinner from './page-animation/spinner';
import './page-animation/transitions.css';

import '../css/plugins.css';
import '../css/style.css';
import '../css/skin/skin-1.css';
import '../css/templete.min.css';

const About1 = lazy(() => import('./pages/about-1'));
const BlogHalfImg = lazy(() => import('./pages/blog-half-img'));
const Contact3 = lazy(() => import('./pages/contact-3'));
const Services2 = lazy(() => import('./pages/services-2'));
const Header = lazy(() => import('./layout/header2'));
const Index2 = lazy(() => import('./pages/index-2'));


class Router extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        };
      }
    
      setLoading = (loading) => {
        this.setState({ loading });
      }
      componentDidMount() {
    
        setTimeout(() => {
          this.setState({ loading: false });
        }, 500);
      }
    
    render() {
        const { loading } = this.state;
        // const location = this.props.location;
        return (
    
			//<BrowserRouter>
            <BrowserRouter basename={'/'}>
                    <ScrollToTop />
                    {loading && <Spinner />}
            <Route
                render={({ location }) => (
                    <TransitionGroup>
                         <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={500}
            onEnter={() => this.setLoading(true)}
            onEntered={() => this.setLoading(false)}
          >
                    <div className="page-wraper">
                      <Suspense fallback={<Spinner />}>
                      <Header/>
                        <Switch>
                            <Route path='/' exact component={Index2} />
                            <Route path='/hakkimizda' component={About1} />
                            <Route path='/blog' component={BlogHalfImg} />
                            <Route path='/iletisim' component={Contact3} />
                            <Route path='/hizmetler' component={Services2} />
                          
                        </Switch>
                      </Suspense>
                    </div>
                    </CSSTransition>
                        </TransitionGroup>
                    )}
                />
            </BrowserRouter>

        )
    }
}

export default Router;